import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useQuery } from 'api';
import { useDispatch } from 'hooks';
import { localStorage } from 'api/storages';
import { logout, updateUserData } from 'containers/authorization/actions';
import { getUserId } from 'containers/authorization/selectors';
import { INIT_APP } from 'containers/authorization/api';
import Spinner from 'components/Spinner';

const InitApp = ({ children }) => {
  const userId = useSelector(getUserId);
  const hasToken = Boolean(localStorage.getItem('token'));
  const updateUser = useDispatch(updateUserData);
  const doLogout = useDispatch(logout);
  const onCompleted = useCallback(
    (data) => {
      if (data?.session) {
        updateUser(data.session);
      } else if (userId) {
        doLogout();
        window.location.reload();
      }
    },
    [doLogout, updateUser, userId]
  );
  const { loading, refetch } = useQuery(INIT_APP, {
    fetchPolicy: 'network-only',
    skip: Boolean(userId) || !hasToken,
    onCompleted,
  });
  const handleChangeStore = useCallback(
    ({ key, oldValue, newValue }) => {
      if (!userId || key !== 'token' || oldValue === newValue) return;

      newValue ? refetch() : doLogout();
    },
    [doLogout, refetch, userId]
  );

  useEffect(() => {
    window.addEventListener('storage', handleChangeStore);

    return () => window.removeEventListener('storage', handleChangeStore);
  }, [handleChangeStore]);

  return loading || (hasToken && !userId) ? <Spinner /> : children;
};

InitApp.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InitApp;
